import Webcam from 'webcam-easy';
// import Webcam from './webcam2'; // no

import MobileDetect from 'mobile-detect';

import html2canvas from 'html2canvas';
import axios from 'axios';

import gsap from 'gsap';
import { TweenLite, TweenMax, TimelineLite } from "gsap";

//or get to the parts that aren't included inside TweenMax (works as of 1.19.1):
import Draggable from "gsap/Draggable";

gsap.registerPlugin(Draggable);

import wildcardImagesRelative from './../assets/*.png'; // works
//import wildcardImagesRelative from './../assets/*.*'; 

import $ from 'jquery';


const webcamElement = document.getElementById('webcam');
const canvasElement = document.getElementById('canvas');
const snapSoundElement = document.getElementById('snapSound');
const joueurElement = document.getElementById('joueur');
const slidezoomElement = document.getElementById('slidezoom');
const canvasElementFull = document.getElementById('canvasFull');
const cadreElement = document.getElementById('cadre');

let currentJoueur = 'blank';
let nbessai = 0;
let zoom = 1;



const webcam = new Webcam(webcamElement, 'environment', canvasElement, snapSoundElement, joueurElement);
const md = new MobileDetect(window.navigator.userAgent);
const uuid = localStorage.getItem('uuidfff');

start();

window.addEventListener("orientationchange", function() {
    if (window.orientation == "90" || window.orientation == "-90") {
        alert("Holobleus s'utilise seulement en format portrait");
    } else {

    }
}, false);

$('.draggable').each(function() {
    var drag = $(this);
    Draggable.create(drag, {

        onRelease: function() {

            // console.log('zoom', zoom);

            let posJ = (this.y / zoom) + (120 * zoom);
            let haut = cadreElement.scrollHeight;

            // console.log('this.y', this.y);

            if (posJ > haut) {
                TweenMax.to('#joueur', .5, { y: 0 });
            }
        }
    })

});


function start() {
    $('#intro, #shooting, #rendu, #zoneshare, #zoneshoot, #help, #mention').hide();
    $('#choixjoueur').show();
    TweenMax.from('#grille', .5, { opacity: 0 });
    TweenMax.from('#titretop', .5, { y: -100 });

    // check uuid 

    if (!uuid) {
        localStorage.setItem('uuidfff', create_UUID());
    }

}


window.choixjoueurs = function() {
    $('#intro').hide();
    $('#choixjoueur').show();
};

$('.vgn').click((event) => {
    $('.vgn').removeClass('active');
    currentJoueur = event.target.id;
    TweenMax.to('#' + event.target.id, .3, { opacity: 0.5 });
    TweenMax.to('#' + event.target.id, .3, { opacity: 1, delay: .3 });
    $('#' + event.target.id).addClass('active');
})

// $('#slidezoom').input((event) => {
//     console.log(event.target.value);
//     zoom = Number(event.target.value);
//     TweenMax.to("#joueur", .1, { opacity: 1, scaleX: event.target.value, scaleY: event.target.value, margin: 0 });
// })

slidezoomElement.addEventListener('input', function() {
    zoom = Number(event.target.value);
    TweenMax.to("#joueur", .1, { opacity: 1, scaleX: event.target.value, scaleY: event.target.value, margin: 0 });
}, false);


window.changejoueur = function(joueururl) {
    // console.log('changejoueur', currentJoueur);
    // v2
    if (wildcardImagesRelative.hasOwnProperty(joueururl)) {
        joueurElement.src = './' + wildcardImagesRelative[joueururl];
    }
}

window.startshooting = function() {

    if (currentJoueur === 'blank') {
        window.alert("Vous devez sélectionner un joueur.");
    } else {



        changejoueur(currentJoueur);
        zoom = 1;
        slidezoomElement.value = 1;
        TweenMax.to("#joueur", .5, { opacity: 1, x: 0, y: 0, scaleX: 1, scaleY: 1, margin: 0 });

        // $('.md-modal').addClass('md-show');

        $('#zoneshoot').show();
        $('#intro, #shooting, #rendu, #zoneshare, #choixjoueur, #head, #zonefooter, #mention').hide();


        webcam.start()
            .then(result => {
                cameraStarted(currentJoueur);
                // console.log("webcam started");
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    // console.log('mmobile');

                } else {
                    webcam.flip();
                };

            })
            .catch(err => {
                console.log(err);
                displayError();
            });
    }

}

window.cameraStarted = function() {

    console.log('cameraStarted', currentJoueur);
    $("#errorMsg").addClass("d-none");
    $('.flash').hide();
    // $("#webcam-caption").html("on");
    // $("#webcam-control").removeClass("webcam-off");
    // $("#webcam-control").addClass("webcam-on");
    $(".webcam-container").removeClass("d-none");
    if (webcam.webcamList.length > 1) {
        $("#cameraFlip").removeClass('d-none');
    }
    // $("#wpfront-scroll-top-container").addClass("d-none");
    window.scrollTo(0, 0);
    $('body').css('overflow-y', 'hidden');

}

window.shoot = function() {
    console.log('shoot');
    console.log('currentJoueur', currentJoueur);
    // webcam.flip();
    var picture = webcam.snap();


    if (wildcardImagesRelative.hasOwnProperty(currentJoueur)) {

        // Add joueur
        let context = canvasElement.getContext('2d');
        let contextFull = canvasElementFull.getContext('2d');
        let newjoueur = joueur;
        let posJoueur = getMatrix(joueur);

        // let rappReelx = newjoueur.width / canvasElement.scrollWidth;
        // let rappReely = newjoueur.height / canvasElement.scrollHeight;
        // console.log('posJoueur', posJoueur);
        // console.log('zoom', zoom);
        // console.log('context', context);
        // console.log('contextFull.width', contextFull.width);

        let x;
        let y;

        // console.log('window.innerWidth', window.innerWidth);
        // console.log('canvasElement.width, canvasElement.height', canvasElement.width, canvasElement.height);
        // console.log('canvasElement', canvasElement);

        // rapport
        let factx = canvasElement.width / window.innerWidth;
        let facty = (canvasElement.width * (4 / 3)) / (window.innerWidth * (4 / 3));

        // joueur
        x = posJoueur.x * factx - ((canvasElement.width * zoom - canvasElement.width) / 2);
        y = posJoueur.y * facty - ((canvasElement.height * zoom - canvasElement.height) / 2);

        context.drawImage(newjoueur, x, y, newjoueur.width * factx * zoom, newjoueur.height * facty * zoom);


        // Add copiecam HD
        const copiecam = new Image();
        copiecam.src = picture;
        copiecam.onload = function() {
            console.log('copiecam.width', copiecam.width);
            contextFull.drawImage(copiecam, 0, 0, 1200, 1600);

            // add Joueur HD 
            let xHd = x * (canvasElementFull.width / canvasElement.width);
            let yHd = y * (canvasElementFull.height / canvasElement.height);
            contextFull.drawImage(newjoueur, xHd, yHd, 1200 * zoom, 1600 * zoom);

            // add Logo HD
            const base_logoHD = new Image();
            base_logoHD.src = wildcardImagesRelative['logo'];
            base_logoHD.onload = function() {
                // context.imageSmoothingQuality = 'high';
                contextFull.drawImage(base_logoHD, 935, 25, base_logoHD.width / 2, base_logoHD.height / 2);

                // Add logo fff HD
                const base_logofffHD = new Image();
                base_logofffHD.src = wildcardImagesRelative['logo-fff'];
                base_logofffHD.onload = function() {
                    contextFull.drawImage(base_logofffHD, 25, 20, base_logofffHD.width, base_logofffHD.height);
                };


            };

        }



        // Add logo
        const base_logo = new Image();
        base_logo.src = wildcardImagesRelative['logo'];
        base_logo.onload = function() {
            // context.imageSmoothingQuality = 'high';
            context.drawImage(base_logo, canvasElement.width - (canvasElement.width / 6) - 10, 15, canvasElement.width / 6, canvasElement.height / 12);
        };


        // Add logo fff
        const base_logofff = new Image();
        base_logofff.src = wildcardImagesRelative['logo-fff'];
        base_logofff.onload = function() {
            context.drawImage(base_logofff, 15, 15, canvasElement.width / 6, canvasElement.height / 12);
        };

        nbessai++;
        webcam.stop();
    };

    // $('.md-modal').removeClass('md-show');
    $('#intro, #choixjoueur, #shooting, #zonevalide, #head, #zoneshoot').hide();
    $('#zoneshare, #zonefooter').show();

    TweenMax.from('#zoneshare', .5, { opacity: 0, y: 800 });
    TweenMax.from('#titrehach', .5, { y: -100 });
    TweenMax.from('#canvas', 1.5, { opacity: 0 });


    $('#rendu').show().animate({
            opacity: 1
        }, 500)
        .fadeIn(500)
        .css({
            'opacity': 0.1
        });



}

window.reloadshoot = function() {
    $('#intro, #choixjoueur, #rendu').hide();
    $('#shooting').show();
    startshooting(currentJoueur);
}

window.recordshoot = function() {
    webcam.stop();
    // Record shoot 
    console.log("save");
    // console.log(nbessai);
    // console.log(currentJoueur);

    axios.post('https://api.holobleus.com/sessions', {
            "nbessai": nbessai,
            "joueur": currentJoueur,
            "created_by": "1",
            "updated_by": "1",
            "uuid": uuid,
            "device": md.phone(),
            "os": md.os()
        })
        .then(function(response) {
            console.log('save ok');
            if (navigator.userAgent.match('CriOS')) {
                console.log('chrome');

                alert("Votre navigateur n'est pas compatible avec cette fonction, merci d'utiliser Safari sur iOs");


            } else {

                let link = document.createElement('a');
                link.download = 'Intermarche-holobleus.jpg';
                link.id = 'holobleus';
                // link.target = '_blank';

                let dt = canvasElementFull.toDataURL('image/jpeg', 1.0);

                /* Change MIME type to trick the browser to downlaod the file instead of displaying it */
                dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');

                /* In addition to <a>'s "download" attribute, you can define HTTP-style headers */
                dt = dt.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=holobleus.jpg');

                link.href = dt;
                //link.href = canvasElementFull.toDataURL("image/webp", 1.0);
                // link.href = canvasElementFull.toDataURL("image/webp", 1.0);
                // link.target = '_blank';
                link.click();
            };

        })
        .catch(function(error) {
            console.log(error);
        });

    // help
    if (md.is('iPhone') && md.userAgent() === 'Safari') {
        TweenMax.to('#help', 0, { opacity: 0 });
        $('#help').show();
        TweenMax.to('#help', .5, { top: 0, opacity: 1, delay: 2 });
        TweenMax.to('#help', 1, { opacity: 0, delay: 6 });
    };



}

window.beforeTakePhoto = function() {
    $('.flash')
        .show()
        .animate({
            opacity: 0.3
        }, 500)
        .fadeOut(500)
        .css({
            'opacity': 0.7
        });
    window.scrollTo(0, 0);
    // $('#webcam-control').addClass('d-none');
    $('#cameraControls').addClass('d-none');
}

window.afterTakePhoto = function() {
    webcam.stop();
    $('#canvas').removeClass('d-none');
    $('#download-photo').removeClass('d-none');
    $('#resume-camera').removeClass('d-none');
    $('#cameraControls').removeClass('d-none');
}

window.changecam = function() {
    var picture = webcam.flip();
}

window.displayError = function(err = '') {
    if (err != '') {
        $("#errorMsg").html(err);
    }
    $("#errorMsg").removeClass("d-none");
}

window.liremention = function() {
    $('#mention').show();
    $('#intro, #shooting, #rendu, #zoneshare, #choixjoueur, #head, #zonefooter, #zoneshoot').hide();
}

window.hidemention = function() {
    $('#choixjoueur').show();
    $('#mention').hide();
}

function getMatrix(element) {
    const testelem = element.style.transform;
    if (testelem) {
        const values = element.style.transform.split(/\w+\(|\);?/);
        const transform = values[1].split(/,\s?/g).map(numStr => parseInt(numStr));

        return {
            x: transform[0],
            y: transform[1],
            z: transform[2]
        };
    } else {
        return {
            x: 0,
            y: 0,
            z: 0
        };
    }
}

function create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}